import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Divider,
  Grid,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { helpdata } from 'data/helpdata';
import { useTheme, styled } from '@mui/material/styles';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Refresh, YouTube } from '@mui/icons-material';
import {isMobile} from 'react-device-detect';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));


const HelpSearchModal = ({ isOpen, onClose }) => {
    const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  
  useEffect(() => {
     const filteredResults = helpdata.filter((item) =>
       item.tags.toLowerCase().includes(searchTerm.toLowerCase())
     );
     setSearchResults(filteredResults);
  }, [searchTerm]);

  const handleClose = () => {
    // setSearchTerm('');
    // setSearchResults([]);
    onClose();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleResultSelect = (result) => {
    onResultSelect(result);
    handleClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '5px',
          width:'90%',
          height:'80%'
        }}
      >
        <Typography variant='h3'>Search Help Video</Typography>
        <IconButton onClick={handleClose} style={{ position: 'absolute', top: 10, right: 10 }}>
          <CloseIcon />
        </IconButton>
        <Divider sx={{mt:2}}/>
        <TextField
         sx={{mt:2}}
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <PerfectScrollbar
            style={{ height: '100%', maxHeight: isMobile ? 'calc(100vh - 350px)' : 'calc(100vh - 280px)', overflowX: 'hidden' }}
        >
        <List  sx={{
                width: '100%',
                //maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}>
          {searchResults.map((result,index) => (
            <ListItemWrapper key={index} >
            <ListItem  >
            <ListItemText primary={<Typography variant='h3'>{result.title}</Typography>} />
                   
              
            </ListItem>
            <Grid container direction="column" >
                    <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography variant="body2">{result.detail}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container >
                            <Grid item>
                                
                                <Button startIcon={<YouTube />} variant="contained" disableElevation onClick={()=> window.open(result.youtube_link, '_blank', 'noopener,noreferrer')}>
                                    Open Help Video
                                </Button>
                               
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{pt:2}}/>
            </ListItemWrapper>
          ))}
        </List>
        </PerfectScrollbar>
      </div>
    </Modal>
  );
};

export default HelpSearchModal;