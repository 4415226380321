import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <div>
        <Outlet />
        {/* <Customization /> */}
    </div>
);

export default MinimalLayout;
