import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import SelectField from '../../libs/formik_fields/SelectField';
import { getActiveTyreBrandListRequest } from '../../services/tire_brand';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { getActiveTyreSizeListRequest } from '../../services/tyre_size';
import SelectInput from '../../libs/inputs/SelectInput';
import { FieldArray } from 'formik';
import { toast } from 'react-toastify';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';
import { Delete as DeleteIcon, History } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectIsServiceActive } from '../../store/slices/serviceSlice';
import appServices from '../../appServices';
import TyreInventoryAndPriceChangeHistory from './TyreInventoryAndPriceChangeHistory';

const emptyTyre = {
    name: '',
    description: '',
    status: 1,
    tyreBrand: null,
    stock: []
};

const tyreSchema = yup.object().shape({
    name: yup.string().required('Please enter name of tyre.'),
    description: yup.string('Please enter valid description.'),
    status: yup.mixed().required('Status is required.'),
    tyreBrand: yup.mixed().required('Tyre brand is required.'),
    stock: yup.array().of(
        yup.object().shape({
            size: yup.mixed().required('Size is required.'),
            stock: yup.number().required('Stock is required.'),
            status: yup.mixed().required('Stock Status is required.')
        })
    )
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const TyreFormContent = ({ isSubmitting, values }) => {
    const [selectedSize, setSelectedSize] = useState(null);

    const [tyrePayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.TYRE });
    const [tyreBrandOptionsList, setTyreBrandOptionsList] = useState([]);
    const [tyreBrandsOptionsListLoading, setTyreBrandsOptionsListLoading] = useState(true);

    const isTyreStockServiceActive = useSelector(selectIsServiceActive(appServices.TYRE_STOCK_MANAGEMENT));
    const isTyreStockWithBillingServiceActive = useSelector(selectIsServiceActive(appServices.TYRE_STOCK_MANAGEMENT_BILLING));

    useEffect(() => {
        async function fetchData() {
            setTyreBrandsOptionsListLoading(true);
            const payload = tyrePayloadCreator({});
            const data = await getActiveTyreBrandListRequest(payload);
            if (Array.isArray(data)) {
                setTyreBrandOptionsList(data);
            }
            setTyreBrandsOptionsListLoading(false);
            console.log('-> data', data);
        }

        fetchData();
    }, []);

    const [tyreSizeOptionsList, setTyreSizeOptionsList] = useState([]);
    const [tyreSizeOptionsListLoading, setTyreSizeOptionsListLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setTyreSizeOptionsListLoading(true);
            const payload = tyrePayloadCreator({});
            const data = await getActiveTyreSizeListRequest(payload);
            if (Array.isArray(data)) {
                setTyreSizeOptionsList(data);
            }
            setTyreSizeOptionsListLoading(false);
            console.log('-> data', data);
        }

        fetchData();
    }, []);

    const selectedTyreSizeIds = useMemo(() => {
        if (values.stock && Array.isArray(values.stock) && values.stock.length > 0) {
            return values.stock.map((item) => item?.size?.id);
        }
        return [];
    }, [values.stock]);

    useEffect(() => {
        if (selectedTyreSizeIds && Array.isArray(selectedTyreSizeIds) && selectedTyreSizeIds.length > 0) {
            console.log('filtering tyre size list', tyreBrandOptionsList, selectedTyreSizeIds);
            setTyreSizeOptionsList((items) => items.filter((item) => !selectedTyreSizeIds.includes(item.id)));
        }
    }, [selectedTyreSizeIds, tyreSizeOptionsListLoading]);

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.TYRE);
    }, [navigate]);

    const handleAddSize = () => {};

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <SelectField name="tyreBrand" placeholder="Company" valueKey="id" labelKey="name" options={tyreBrandOptionsList} />
                <TextField name="name" label="Tyre*" />
                {values.editMode && <StatusSwitchField name="status" label="Status" />}
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField name="description" label="Description" rows={4.5} multiline />
            </Grid>
            {isTyreStockServiceActive && (
                <Grid item xs={12}>
                    <FieldArray
                        name="stock"
                        render={(arrayHelpers) => (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography m={0} variant="h3">
                                        Available Sizes
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <SelectInput
                                                id="tyreSize"
                                                placeholder="Select Size"
                                                valueKey="id"
                                                labelKey="size"
                                                options={tyreSizeOptionsList}
                                                onChange={setSelectedSize}
                                                value={selectedSize}
                                                menuPlacement="top"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                sx={{
                                                    ml: 1
                                                }}
                                                onClick={() => {
                                                    if (selectedSize) {
                                                        arrayHelpers.push({
                                                            size: selectedSize,
                                                            stock: 0,
                                                            status: 1,
                                                            isNew: true
                                                        });
                                                        setSelectedSize(null);
                                                    } else {
                                                        toast.error('Please select a size');
                                                    }
                                                }}
                                            >
                                                Add Size
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} pb={2}>
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Sno</TableCell>
                                                    <TableCell align="left">Size</TableCell>
                                                    <TableCell align="center">Available</TableCell>
                                                    <TableCell align="center">Stock</TableCell>
                                                    {isTyreStockWithBillingServiceActive && <TableCell align="center">Price</TableCell>}
                                                    <TableCell align="center">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values.stock.map((row, index) => (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell valign="top" align="left">
                                                            {row?.size?.size}
                                                        </TableCell>
                                                        <TableCell valign="top" align="center">
                                                            <StatusSwitchField name={`stock["${index}"].status`} center />
                                                        </TableCell>
                                                        <TableCell valign="top" align="center">
                                                            <TextField
                                                                type="number"
                                                                name={`stock["${index}"].stock`}
                                                                showErrorText={false}
                                                            />
                                                        </TableCell>
                                                        {isTyreStockWithBillingServiceActive && <TableCell valign="top" align="center">
                                                            <TextField
                                                                type="number"
                                                                name={`stock["${index}"].price`}
                                                                showErrorText={false}
                                                            />
                                                        </TableCell>}
                                                        <TableCell valign="top" align="center">
                                                            {row.isNew && (
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )}
                                                            {!row.isNew && (
                                                                <TyreInventoryAndPriceChangeHistory key={index} buttonText='Change History' stock={row} />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {values.stock.length === 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={4} align="center">
                                                            Please add at least one size
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    />
                </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        ml: 1
                    }}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Grid>
        </Grid>
    );
};

function TyreForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyTyre}
            onSubmit={handleSubmit}
            validationSchema={tyreSchema}
            FormContent={TyreFormContent}
        />
    );
}

export default TyreForm;
